/*
 * @Author: your name
 * @Date: 2020-09-23 13:51:53
 * @LastEditTime: 2020-09-23 15:16:59
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-workbench\src\store\getters.js
 */
/*
 * @Author: your name
 * @Date: 2020-09-23 13:51:53
 * @LastEditTime: 2020-09-23 14:06:15
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-workbench\src\store\getters.js
 */
const getters = {
  groupInfo: state => state.app.groupInfo,
  groupDefault:state=> Object.keys(state.app.groupInfo).length==0,
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  sideBarStyle: state => state.app.theme.sideBarStyle,
  mainColor: state => state.app.theme.mainColor,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  status: state => state.user.status,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  errorLogs: state => state.errorLog.logs,
  userImageUrl: state => state.user.userImageUrl,
  permissions: state => state.permission.permissions,
  notReadNoticeCount: state => state.user.notReadNoticeCount,
  currentRole: state => state.user.currentRole,
  roleList: state => state.user.roleList,
  employeeId: state => state.user.employeeId,

  boundOrgId : state => state.app.boundOrgId
}
export default getters
