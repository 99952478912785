import {routers} from '@/router'
import url from 'url'
// 是否生成了首页
let hasAddedIndex = false
let indexRouter = null

/**
 * 递归过滤异步路由表
 * @param routers
 */
// 微前端兼容
let prefix = "/micro";
function filterAsyncRouter(routers, parent, isFirst, isApp) {
  const accessedRouters = []
  routers.every((item) => {
    // 只展示模块和菜单【按钮里面如果是路由的也需要处理一下，放到菜单级别里】
    if (item.menuLevel === '2' || item.menuLevel === '3' || isApp) {
      const router = {}
      const meta = {}
      router.path = ''
      if (item.url) {
        if (item.url.indexOf("http://") == 0 || item.url.indexOf("https://") == 0 || item.url.indexOf("/http://") == 0 || item.url.indexOf("/https://") == 0) {
          router.path = item.url
        } else {
          const urlObj = url.parse(item.url, true);
          if (urlObj.protocol == null) {
            router.path = urlObj.pathname
            // 默认查询数据
            if (urlObj.query) {
              meta.query = urlObj.query
            }
          } else {
            // 路由path
            router.path = item.url
          }
          router.path=prefix+router.path
        }
        // console.log(router.path)
      }

      meta.idPath = (parent ? parent.meta.idPath : '') + '/' + item.id
      // 路由name 改回后台配置的路由名，因为希望同一组件可配成多个url
      router.name = item.menuRouterName
      if (item.isHide === '1' || isApp) {
        router.hidden = true
      }
      meta.id = item.id
      if (item.menuName) {
        // 路由title
        meta.title = item.menuName
      }
      if (item.menuIcon) {
        // 菜单图标
        meta.icon = item.menuIcon
      }
      meta.menuLevel = item.menuLevel
      router.meta = meta
      if (item.menuLevel === '2' && item.children && item.children.length > 0 && !isApp) {
        // 添加子节点
        router.children = filterAsyncRouter(item.children, router, false, isApp)
        item.path = ''
        router.path = ''
      }
      if (router.path) {
        if (router.path.indexOf("/") == 0) {
          router.path = router.path.substring(1);
        }
      }
      // 添加路由
      if ((isFirst && item.menuLevel === '3') || isApp) {
        accessedRouters.push({
          path: '',
          redirect: 'noredirect',
          name: '',
          children: [router]
        })
      } else {
        accessedRouters.push(router)
      }
      return true
    }
  })
  return accessedRouters
}

const permission = {
  state: {
    routers,
    addRouters: [],
    permissions: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      // state.routers = routers.concat(routers)
      state.routers = routers
      console.log(state.routers,'---state.routers')
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions || []
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const routersTree = data.employeeMenuTree
        // 处理路由tree
        const accessedRouters = filterAsyncRouter(routersTree, null, true, false)
        console.log(accessedRouters,'--accessedRouters')
        commit('SET_ROUTERS', accessedRouters)
        commit('SET_PERMISSIONS', data.permissions)
        resolve()
      })
    },
    // GenerateAppRoutes({ commit }, appMenuList) {
    //   return new Promise(resolve => {
    //     // 处理路由tree
    //     const accessedRouters = filterAsyncRouter(appMenuList, null, false, true)
    //     resolve(accessedRouters)
    //   })
    // }
  }
}

export default permission
