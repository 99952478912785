/*
 * @Author: your name
 * @Date: 2020-09-24 16:46:15
 * @LastEditTime: 2020-09-24 17:00:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-workbench\src\icons\index.js
 */
// requires and returns all modules that match
import Vue from 'vue'
import SvgIcon from '../components/SvgIcon'

console.log('111111111111111111')
// import generateIconsView from '@/pages/svg-icons/generateIconsView.js'
Vue.component('svg-icon', SvgIcon)


// import all svg
const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context('./svg', true, /\.svg$/);

requireAll(req);

// generateIconsView.generate(iconMap) // j