import request from '@/utils/request'
// 登陆
export function loginByUsername(username, password) {
  const data = {
    username,
    password
  }
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/loginByRole`,
    method: 'post',
    data
  })
  // return request({
  //   url: '/login/login',
  //   method: 'post',
  //   data
  // })
}

export function userLogin(username, password, loginType) {
  const data = {
    username,
    password,
    loginType
  }
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/loginByRole`,
    method: 'post',
    data
  })
}

export function changRole(roleCode) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/changRole/${roleCode}`,
    method: 'post'
  })
}

export function userLoginByMixId(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/loginByMixId`,
    method: 'post',
    data
  })
}

export function getVerificationCode(phoneNumber, activityType,loginDomain) {
  const data = {
    phoneNumber,
    activityType,
    loginDomain
  }
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/commons/getVerificationCode`,
    method: 'post',
    params: data
  })
}

export function changePasswordOne(userName, passWord) {
  const data = {
    userName,
    passWord
  }
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/changePasswordOne`,
    method: 'post',
    data
  })
}

export function changePasswordTwo(newPassword, repeatPassword, token) {
  const data = {
    newPassword,
    repeatPassword,
    token
  }
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/changePasswordTwo`,
    method: 'post',
    data
  })
}

export function checkVerificationCode(phoneNumber, verificationCode) {
  const data = {
    phoneNumber,
    verificationCode
  }
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/commons/checkVerificationCode`,
    method: 'post',
    params: data
  })
}

export function logout() {
  return request({
    url: '/login/logout',
    method: 'post'
  })
}

export function getUserInfo(systemType) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/user/${systemType}`,
    method: 'get'
  })
}

export function getRegions(parentId) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/systemRegion/getRegions`,
    method: 'get',
    params: { parentId }
  })
}

export function register(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/groupInfo/register`,
    method: 'post',
    data
  })
}
export function getByRoleDaoKeMessage(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/getByRoleDaoKeMessage/`+data,
    method: 'get',
    data
  })
}
// 创建密码
export function resetPassword(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}${process.env.VUE_APP_BASE_URL}/employeeInfo/resetPassword`,
    method: 'post',
    data,
  })
}