<!-- 设置密码dialog -->
<template>
  <div class="SetPasswordDialog">
    <el-dialog class="toastDialog" title="密码创建提示" :visible.sync="show1" width="26%" center :show-close="false">
      <span class="dia__info">您的账户当前还未设置密码，为了您的账户安全，请先设置密码</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show1 = false">下次再说</el-button>
        <el-button type="primary" @click="setPassWord">设置密码</el-button>
      </span>
    </el-dialog>
    <el-dialog title="创建密码" :visible.sync="show2" width="26%" center :show-close="false">
      <div class="createPassword">
        <div class="createPassword__item">
          <el-input placeholder="请输入密码" v-model="pwd" show-password minlength="6" maxlength="16"
            @blur="blurPwd"></el-input>
          <span class="pwdError" v-if="pwdError">{{ pwdError }}</span>
        </div>
        <div class="createPassword__item">
          <el-input placeholder="请再次确认密码" v-model="pwd1" show-password minlength="6" maxlength="16"
            @blur="blurPwd1"></el-input>
          <span class="pwdError" v-if="pwdError1">{{ pwdError1 }}</span>
        </div>
        <div class="createPassword__item">
          <p class="remark">密码长度6-16位</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createClose">关闭</el-button>
        <el-button type="primary" @click="createPwd">创建密码</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import { resetPassword } from "@/api/login"
export default {
  name: "SetPasswordDialog",
  data() {
    return {
      show1: false,
      show2: false,
      pwd: "",
      pwd1: "",
      pwdError: "",
      pwdError1: "",
    }
  },
  components: {},
  computed: {},
  created() { },
  mounted() {
    let _this = this;
    window.$eventBus.$on("setPassWordDialog_open", () => {
      _this.open();
    })
  },
  methods: {
    open() {
      this.show1 = true;
      this.pwd = "";
      this.pwd1 = "";
    },
    close() {
      this.show1 = false;
    },
    setPassWord() {
      this.show2 = true;
    },
    blurPwd() {
      if (this.pwd.trim().length == 0) {
        return this.pwdError = "密码不能为空";
      }
      if (this.pwd.trim().length < 6) {
        return this.pwdError = "密码长度需大于6位";
      }
      if (this.pwd == '888888') {
        return this.pwdError = "密码过于简单，请更换其他密码";
      }
      this.pwdError = "";
    },
    blurPwd1() {
      if (this.pwd1.trim().length == 0) {
        return this.pwdError1 = "密码不能为空";
      }
      if (this.pwd1.trim().length < 6) {
        return this.pwdError1 = "密码长度需大于6位";
      }
      if (this.pwd1 != this.pwd) {
        return this.pwdError1 = "密码不一致";
      }
      this.pwdError1 = "";
    },
    // 创建密码
    async createPwd() {
      if (this.pwdError != "" || this.pwdError1 != "") {
        return;
      }
      let query = {
        password: this.pwd1,
      }
      let res = await resetPassword(query);
      if (res.data.code == 200 && res.data.data) {
        this.$message.success("设置密码成功");
        this.show1 = false;
        this.show2 = false;
      } else {
        this.$message.error(res.data.msg);
      }
    },
    createClose() {
      this.show2 = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.SetPasswordDialog {
  /deep/ .el-dialog {
    margin-top: 30vh !important;
    border-radius: 8px;
  }
  .toastDialog{
    /deep/.el-dialog__body{
      text-align: center;
    }
  }

  .createPassword {
    &__item:not(:last-child) {
      margin-bottom: 20px;
    }

    &__item {
      position: relative;

      .pwdError {
        position: absolute;
        left: 0;
        bottom: -16px;
        color: red;
        font-size: 12px;
      }
    }

    .remark {
      color: #999;
    }
  }
}
</style>
