/*
 * @Author: your name
 * @Date: 2020-08-11 15:14:57
 * @LastEditTime: 2020-08-11 15:19:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-front-pc\src\utils\commFunc.js
 */
// 定义一个图片过滤器
// 过滤MP4文件 获取视频封面
export function DFSImg(path, w, h, type = 0) { //
  if (path == null || path == '') {
    return require('../../static/img/noImg.png')
  }

  let baseImg = process.env.VUE_APP_IMG_DOMAIN
  if (!Array.isArray(baseImg) && (baseImg.indexOf('aliyun') >= 0 || baseImg.indexOf('cdn') >= 0)) {
    var style = ''
    if (w) style += ',w_' + w
    if (h) style += ',h_' + h
    if (style.length > 0 && path.indexOf('?x-oss-process') == -1) {
      if (path.toLocaleLowerCase().indexOf('.mp4') == -1) {
        if (type == 0) { // 固定宽高，缩略填充
          path += '?x-oss-process=image/resize,m_pad,limit_0' + style
        } else { // 固定宽高，缩略不填充
          path += '?x-oss-process=image/resize,limit_1' + style
        }
      } else {
        if (type == 0) { // 固定宽高，缩略填充
          path += '?x-oss-process=video/snapshot,t_1,m_fast,limit_0,m_pad' + style
        } else { // 固定宽高，缩略不填充
          path += '?x-oss-process=video/snapshot,t_1,m_fast,limit_0' + style
        }
      }
    }
    if (path.indexOf('http') == 0) {
      return path
    }
    return baseImg + path
  } else {
    if (path.indexOf('http') == 0) {
      return path
    }
    if (path.indexOf('/') != 0) {
      path = '/' + path
    }
    if (w && h) {
      path += '.' + w + 'x' + h + '.jpg'
    }
    return baseImg[Math.floor(Math.random() * 100) % baseImg.length] + path
  }
}
