import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import {
  getAllSystemCode
} from '@/api/system/sysCode'
import '../src/icons'
// echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
// common css
import '@/assets/common.scss'
// qiankun框架
import { start } from 'qiankun';
import './plugins/qiankun'
// elementUI
import './plugins/elementUI'
//过滤器
import { DFSImg } from '@/utils/commFunc'
Vue.filter('DFSImg', DFSImg)
//  全局引入vue中央事件总线
import eventBus from './utils/eventBus'

//  全局引入vue中央事件总线
Vue.prototype.$eventBus = eventBus
window.$eventBus = eventBus
// 定义价格保留两位小数过滤器
Vue.filter('keepTwoNum', function (num) {
  num = Number(num)
  return num.toFixed(2)
})

// 保留整数过滤器
Vue.filter('keepIntNum', (num) => {
  num = Number(num)
  return Math.floor(num || 0)
})
// 获取某个类型的系统码表
Vue.prototype.getSysCode = function (type) {
  const data = Vue.prototype.SYS_CODE
  const typeArr = data[type]
  if (!typeArr) {
    return []
  } else {
    return typeArr
  }
}
Vue.config.productionTip = false
// 实例化
getAllSystemCode().then(res=>{
  Vue.prototype.SYS_CODE = res.data.data;
  console.log(res.data,'--res.data')
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
  start();
})





