/*
 * @Author: your name
 * @Date: 2020-09-23 13:51:53
 * @LastEditTime: 2020-10-13 14:22:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-workbench\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
// import errorLog from './modules/errorLog'
import permission from './modules/permission'
import tagsView from './modules/tagsView'
import user from './modules/user'
// import listToEntity from './modules/listToEntity'
// import indicatorAnalysis from './modules/indicatorAnalysis'
import getters from './getters.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  //ABC 下面内容报错注释掉了
  modules: {
    app,
    user,
  //   errorLog,
    permission,
    tagsView,
  //   listToEntity,
  //   indicatorAnalysis
  },
  getters
})

export default store
