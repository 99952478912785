<template>
  <div id="app">
    <router-view></router-view>
    <SetPasswordDialog></SetPasswordDialog>
  </div>
</template>

<script>
import { setToken, getToken } from "@/utils/auth";
import SetPasswordDialog from '@/components/login/SetPasswordDialog'

export default {
  name: "App",
  watch: {
    query: function (n) {
      console.log(n,22211111111)
      //登录 n.token &&
      if (n.token && setToken(n.token)) {
        this.getInfo();
      }
    },
  },
  mounted() {
    if(getToken()) {
      this.getInfo();
    }
    console.log(getToken(),111111111)
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  components: {
    SetPasswordDialog
  },
  methods: {
    getInfo() {
      this.$store
        .dispatch("GetUserInfo")
        .then((res) => {
          const employeeMenuTree = res.data.data.employeeMenuTree;
          const permissions = res.data.data.permissions;
          const params = {
            employeeMenuTree: employeeMenuTree,
            permissions: permissions,
          };
          localStorage.setItem('OrgId',res.data.data.orgList[0].id)
          this.$store.dispatch("GenerateRoutes", params).then(() => {
            // router.addRoutes(store.getters.addRouters);
            // console.log(store.getters.addRouters);
            // let pathArr = [];
            // pathHandler(store.getters.addRouters, pathArr);
            // console.log(pathArr);
            // let onOff = false;
            // pathArr.forEach((item) => {
            //   if (to.path.startsWith(item) && item != "/") {
            //     onOff = true;
            //   }
            // });
            // if (!onOff && to.path != "/home") {
            //   next("/forbidden");
            //   return;
            // }
          });
        })
        .catch(() => {
          // this.$store.dispatch("FedLogOut").then(() => {
          //   Message.error(err || "验证失败,请重新登陆");
          //   next("/login");
          // });
        });
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
</style>
