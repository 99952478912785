/*
 * @Author: your name
 * @Date: 2020-10-13 14:05:06
 * @LastEditTime: 2020-10-13 14:13:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-workbench\src\api\system\sysCode.js
 */
import request from '@/utils/request'

export function getAllSystemCode() {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/sysCode/getAllSysCode`,
    method: 'get'
  })
}

export function fetchSystemCodeList(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/sysCode/queryList`,
    method: 'post',
    data
  })
}

export function updateRidesSystemCode() {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/sysCode/update_redis`,
    method: 'post'
  })
}

export function addSysCode(data) {
  return request({
    url: `${process.env.VUE_APP_OLSHOP_URL}/baseService/sysCode/addOrUpdate`,
    method: 'post',
    data
  })
}
