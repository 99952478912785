import { registerMicroApps } from 'qiankun';
import router from '../router'
import store from '../store'

/* 解决切换主子应用 样式丢失的问题 */
const childRoute = ['/micro'];
const isChildRoute = path => childRoute.some(item => path.startsWith(item))
const rawAppendChild = HTMLHeadElement.prototype.appendChild;
const rawAddEventListener = window.addEventListener;
router.beforeEach((to, from, next) => {
  // 从子项目跳转到主项目
  if (isChildRoute(from.path) && !isChildRoute(to.path)) {
    HTMLHeadElement.prototype.appendChild = rawAppendChild;
    window.addEventListener = rawAddEventListener;
  }
  next();
});

// 预加载
// prefetchApps([{name: 'mayi-unified-admin', entry: '//test-ant.mayi888.cn/'}])

console.log(process.env.VUE_APP_MICRO_APP,'process.env.VUE_APP_MICRO_APP')
/* 注册微应用 */
registerMicroApps(
  [
    // {
    //   name: "ant-micro-order", // app name registered 订单
    //   entry: "//test-micor.mayi888.cn/order/",
    //   container: "#children",
    //   activeRule: "#/order",
    //   props: { data: { store, router, path: '/order' } }
    // },
    // {
    //   name: "ant-micro-setup", // app name registered 设置
    //   entry: "//test-micor.mayi888.cn/setup/",
    //   container: "#children",
    //   activeRule: "#/setup",
    //   props: { data: { store, router, path: '/setup' } }
    // },
    {
      name: "mayi-unified-admin", // app name registered 设置
      entry: process.env.VUE_APP_MICRO_APP,
      container: "#children",
      activeRule: "#/micro",
      props: { data: { store, router, path: '/micro' } }
    },
    // {
    //   name: "ant-micro-financing", // app name registered 财务
    //   entry: "//test-micor.mayi888.cn/financing/",
    //   container: "#children",
    //   activeRule: "#/financing",
    //   props: { data: { store, router, path: '/financing' } }
    // },
    // {
    //   name: "ant-micro-inventory", // app name registered 库存
    //   entry: "//test-micor.mayi888.cn/inventory/",
    //   container: "#children",
    //   activeRule: "#/inventory",
    //   props: { data: { store, router, path: '/inventory' } }
    // },
    // {
    //   name: "ant-micro-base", // app name registered 基础
    //   entry: "//test-micor.mayi888.cn/base/",
    //   container: "#children",
    //   activeRule: "#/base",
    //   props: { data: { store, router, path: '/base' } }
    // },
    // {
    //   name: "ant-micro-customer", // app name registered 基础
    //   entry: "//test-micor.mayi888.cn/customer/",
    //   container: "#children",
    //   activeRule: "#/customer",
    //   props: { data: { store, router, path: '/customer' } }
    // }
  ],
  {
    beforeLoad: app => console.log("before load", app.name),
    beforeMount: [app => console.log("before mount", app.name)]
  }
);

// 默认子应用
// setDefaultMountApp('/order/');