import {
    loginByUsername,
    logout,
    userLoginByMixId,
    getUserInfo,
    userLogin,
    changRole,
    getVerificationCode
  } from '@/api/login';
  // import { getLoginAppMenuList } from '@/api/appRoleRel/appRoleRel'
  import { getToken, setToken, removeToken, getCurrentRoleCode, setCurrentRoleCode } from '@/utils/auth';
  
  const user = {
    state: {
      user: '',
      status: '',
      groupMixId:'',
      code: '',
      token: getToken(),
      name: '',
      avatar: '',
      introduction: '',
      roles: [],
      setting: {
        articlePlatform: []
      },
      groupId: null,
      employeeId: null,
      notReadNoticeCount: 0,
      currentRoleCode: getCurrentRoleCode(),
      currentRole: {},
      roleList: []
    },
  
    mutations: {
      SET_NOT_READ_NOTICE_COUNT: (state, notReadNoticeCount) => {
        state.notReadNoticeCount = notReadNoticeCount;
      },
      SET_CODE: (state, code) => {
        state.code = code;
      },
      SET_TOKEN: (state, token) => {
        state.token = token;
      },
      SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction;
      },
      SET_SETTING: (state, setting) => {
        state.setting = setting;
      },
      SET_STATUS: (state, status) => {
        state.status = status;
      },
      SET_NAME: (state, name) => {
        state.name = name;
      },
      SET_GROUP_ID: (state, groupId) => {
        state.groupId = groupId;
      },
      SET_EMPLOYEE_ID: (state, employeeId) => {
        state.employeeId = employeeId;
      },
      SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
      },
      SET_ROLES: (state, roles) => {
        state.roles = roles;
      },
      SET_IMGURL: (state, userImageUrl) => {
        state.userImageUrl = userImageUrl
      },
      SET_GROUP_MIX_ID:(state,groupMixId)=>{
        state.groupMixId = groupMixId
      },
      SET_CURRENT_ROLE_CODE: (state, currentRoleCode) => {
        if (currentRoleCode) {
          state.currentRoleCode = currentRoleCode;
          setCurrentRoleCode(currentRoleCode)
        } else {
          state.currentRoleCode = '';
          setCurrentRoleCode('')
        }
      },
      SET_CURRENT_ROLE: (state, currentRole) => {
        if (currentRole) {
          state.currentRole = currentRole;
        } else {
          state.currentRole = {};
        }
      },
      SET_ROLE_LIST: (state, roleList) => {
        if (roleList && roleList.length > 0) {
          var roleCodes = []
          var roles = []
          for (let i = 0; i < roleList.length; i++) {
            if (roleCodes.indexOf(roleList[i].roleCode) < 0) {
              roleCodes.push(roleList[i].roleCode)
              roles.push(roleList[i])
            }
          }
          state.roleList = roles
        } else {
          state.roleList = [];
        }
      },
    },
  
    actions: {
      SetNotReadNoticeCount({ commit }, notReadNoticeCount) {
        commit('SET_NOT_READ_NOTICE_COUNT', notReadNoticeCount || 0);
      },

      // 用户名登录
      LoginByUsername({ commit, dispatch }, userInfo) {
        const username = userInfo.username.trim();
        return new Promise((resolve, reject) => {
          loginByUsername(username, userInfo.password)
            .then(response => {
              if (response.data.code == 200) {
                const data = response.data;
                commit('SET_TOKEN', data.data.token);
                commit('SET_CURRENT_ROLE_CODE', data.data.roleCode);
                setToken(data.data.token);
                resolve();
              } else {
                this.$router.push({ path: '/login' });
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
  
      // 用户登录(带上企业加密id)
      UserLoginByMixId({commit},userInfo) {
        return new Promise((resolve, reject) => {
          var data = {
            username:userInfo.userName,
            password:userInfo.passWord,
            loginType: userInfo.loginType,
            groupMixId:userInfo.groupMixid
          }
          console.info(data,"usejs133");
          userLoginByMixId(data)
            .then(response => {
              if (response.data.code == 200) {
                const data = response.data
                const token =  data.data.token
                commit('SET_GROUP_MIX_ID', userInfo.groupMixid);
                commit('SET_TOKEN', token)
                commit('SET_CURRENT_ROLE_CODE', data.data.roleCode);
                setToken(token)
                resolve()
              } else {
                this.$router.push({ path: '/login' });
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
  
      // 用户登陆（手机号码/邮箱/用户名 + 密码登陆，手机号码+验证码登陆）
      UserLogin({ commit, dispatch }, userInfo) {
        // const username = userInfo.userName.trim()
        return new Promise((resolve, reject) => {
          userLogin(userInfo.userName, userInfo.passWord, userInfo.loginType)
            .then(response => {
              if (response.data.code == 200) {
                const data = response.data
                console.log(data.data.employeeDetailResponseDTO,'--data.data.employeeDetailResponseDTO')
                if (data.data.employeeDetailResponseDTO && data.data.employeeDetailResponseDTO.hasPassword == "false") {
                  window.$eventBus.$emit("setPassWordDialog_open");
                }
                const token =  data.data.token
                commit('SET_TOKEN', token)
                commit('SET_CURRENT_ROLE_CODE', data.data.roleCode);
                setToken(token)
                dispatch('GetUserInfo').then(res => {
                  const employeeMenuTree = res.data.data.employeeMenuTree;
                  const permissions = res.data.data.permissions;
                  const params = {
                    employeeMenuTree: employeeMenuTree,
                    permissions: permissions,
                  };
                  dispatch("GenerateRoutes", params)
                  console.log(res,6666666666666)
                })
                resolve()
              } else {
                this.$router.push({ path: '/login' });
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
  
      // 角色切换
      ChangRole({ commit }, roleCode) {
        return new Promise((resolve, reject) => {
          changRole(roleCode)
            .then(response => {
              if (response.data.code == 200) {
                const data = response.data;
                commit('SET_TOKEN', data.data.token);
                commit('SET_CURRENT_ROLE_CODE', data.data.roleCode);
                setToken(data.data.token);
                resolve();
              } else {
                this.$router.push({ path: '/login' });
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
  
      // 获取验证码
      getVerificationCode({ commit }, userInfo) {
        const username = userInfo.userName;
        return new Promise((resolve, reject) => {
          getVerificationCode(username, userInfo.verificationtype)
            .then(response => {
              if (response.data.code == 200) {
                resolve();
              }
              reject()
            })
            .catch(error => {
              reject(error);
            });
        });
      },
  
      // 获取用户信息
      GetUserInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
          getUserInfo(1)
            .then(response => {
              if (!response.data) {
                // 由于mockjs 不支持自定义状态码只能这样hack
                reject('error');
              }
              const data = response.data;
              if (data.code == 200) {
                console.log(data.data);
                // 验证返回的roles是否是一个非空数组
                if (!data.data.roleInfos || data.data.roleInfos.length === 0) {
                  reject('权限验证失败,请确认账号是否分配角色!')
                  return
                }
  
                if (!data.data.employeeMenuTree || data.data.employeeMenuTree.length === 0) {
                  // reject('权限验证失败,请确认该角色是否分配菜单!')
                  // return
                  response.noMenu = true
                }
  
                // GrowingIO用户id
                // gio('setUserId', data.data.id)
                // gio('people.set', 'realName', data.data.realName)
  
                commit('SET_NAME', data.data.realName); //真实姓名
                commit('SET_IMGURL', data.data.userImageUrl); //头像
                // 总部id
                commit('SET_GROUP_ID', data.data.groupId);
                // 员工id
                commit('SET_EMPLOYEE_ID', data.data.id);
                // 当前角色
                commit('SET_CURRENT_ROLE', data.data.currentRole);
                // 角色列表
                commit('SET_ROLE_LIST', data.data.roleInfos);
                //commit('SET_INTRODUCTION', data.introduction)
                resolve(response);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
    
  
      // 第三方验证登录
      // LoginByThirdparty({ commit, state }, code) {
      //   return new Promise((resolve, reject) => {
      //     commit('SET_CODE', code)
      //     loginByThirdparty(state.status, state.email, state.code).then(response => {
      //       commit('SET_TOKEN', response.data.token)
      //       setToken(response.data.token)
      //       resolve()
      //     }).catch(error => {
      //       reject(error)
      //     })
      //   })
      // },
  
      // 登出
      LogOut({ commit, state }) {
        return new Promise((resolve, reject) => {
          logout(state.token)
            .then(() => {
              commit('SET_TOKEN', '');
              commit('SET_ROLES', []);
              removeToken();
              // GrowingIO用户id
              // gio('clearUserId')
              resolve();
            })
            .catch(error => {
              reject(error);
            });
        });
      },
  
      // 前端 登出
      FedLogOut({ commit }) {
        return new Promise(resolve => {
          commit('SET_TOKEN', '');
          removeToken();
          resolve();
        });
      },
  
      // 动态修改权限
      ChangeRoles({ commit, dispatch }, role) {
        return new Promise(resolve => {
          commit('SET_TOKEN', role);
          setToken(role);
          getUserInfo(role).then(response => {
            const data = response.data;
            commit('SET_ROLES', data.roles);
            commit('SET_NAME', data.name);
            commit('SET_AVATAR', data.avatar);
            commit('SET_INTRODUCTION', data.introduction);
            const employeeMenuTree = response.data.data.employeeMenuTree;
            const permissions = response.data.data.permissions;
            const params = {
              employeeMenuTree: employeeMenuTree,
              permissions: permissions,
            };
            dispatch("GenerateRoutes", params)
            resolve();
          });
        });
      }
    }
  };
  
  export default user;
  