import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  getToken,setToken
} from '@/utils/auth' // getToken from cookie

Vue.use(VueRouter)
const routes = [
  {
    path: "*",
    component: () => import("@/pages/Layout.vue"),
    children: [
      {
        path: "/home",
        component: () => import("@/pages/Home.vue"),
        hidden: true
      },{
        path: '/personal/info',
        component: () => import('@/pages/employee/personal/info'),
        name: 'PersonalInfo',
        hidden: true,
        meta: {
          title: '个人信息',
          componentName: 'PersonalInfo'
        }
      },
    ],
    hidden: true
  },
  {
    path: "/",
    redirect: "/Home",
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/pages/Login'),
    hidden: true,
    children: [{
      path: '/',
      component: () =>
        import('@/components/login/signin'),
      hidden: true
    },
    // {
    //   path: '/register',
    //   component: () =>
    //     import('@/components/login/register'),
    //   hidden: true
    // },
    {
      path: '/forgetpwd',
      component: () =>
        import('@/components/login/reset'),
      hidden: true
    }]
  }
];

// 无需登录即可访问的URL白名单,正则表达式
const whiteList = [
  '/protal',
  '/login',
  '/loginMain',
  '/forgetpwd',
  '/register',
  '/readArticle',
  '/readAttchment',
  '/authredirect',
  '/opendoc',
  '/groupLogin/.*',
  '/wechatLodding',
  '/outAuthredirect',
  '/readArticle',
  '/announce/.*',
  '/metabase/login',
  '/sso/login']

function isWhite(path) {
  for (let index = 0; index < whiteList.length; index++) {
    const whiteUrl = whiteList[index]
    const reg = new RegExp(whiteUrl, 'g')
    if (reg.test(path)) {
      return true
    }
  }
  return false
}
let router = new VueRouter({ routes })
router.beforeEach((to, from, next) => {
  if(to.query&&getToken()&&to.query.token){
    if(to.query.token == getToken()){
      next()
    }else {
      setToken('')
    }
  }

  if (getToken()) { // determine if there has token
    /* has token*/

    if(isWhite(to.path) && !(to.path === '/login' || to.path.indexOf("/groupLogin/") != -1)){
      next();
      return
    }
    if (to.path === '/login' || to.path.indexOf("/groupLogin/") != -1) {
      next({
        path: '/'
      })
    } else {
        next()
    }
  } else {
    // console.log('d', )
    if (isWhite(to.path)) { // 在免登录白名单，直接进入
      next()
    } else {
      console.log(to,from)
      if(localStorage.getItem('toHome') == '1'){
        next(`/login`)
      }else{
        next(`/login?pages=${to.path}`) // 否则全部重定向到登录页
      }
    }
  }
})

export default router