/*
 * @Author: your name
 * @Date: 2020-09-23 13:51:53
 * @LastEditTime: 2020-09-23 16:19:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ghsc-workbench\src\utils\request.js
 */
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'


const service = axios.create({
  baseURL: process.env.BASE_API,
  timeout: 500000
})
service.interceptors.request.use(
  config => {
    // config.headers['Mayi-Token'] = localStorage.getItem('Mayi-Token') || ''
    config.headers['Mayi-Token'] = getToken()
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// respone interceptor
service.interceptors.response.use(
  /**
     * 下面的注释为通过在response里，自定义code来标示请求状态
     * 当code返回如下情况则说明权限有问题，登出并返回到登录页
     * 如想通过xmlhttprequest来状态码标识 逻辑可写在下面error中
     * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
     */
  response => {
    const res = response.data
    res.ok = !(res.ok === 'false' || !res.ok)
    if (res.code !== '200') {
      if (res.msg) {
        Message({
          message: res.msg,
          type: 'error',
          duration: 2 * 1000
        })
      }
      // let href = encodeURIComponent(location.href)
      // 未登录res.code === '-1' ||
      if (res.code === 401) {
        // 请自行在引入 MessageBox
        MessageBox.confirm('你已被退出，请重新登录', '确定退出', {
          confirmButtonText: '重新登录',
          type: 'warning'
        }).then(() => {

          store.dispatch('FedLogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }).catch(() => {
          // window.location.href = '/login'
          store.dispatch('FedLogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      }
      // return Promise.reject('error')
      return response
    } else {
      return response
    }
  },
  error => {
    console.log('err:' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
