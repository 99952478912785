const app = {
    namespaced: true,
    state : {
        boundOrgId : ''
    },
    mutations : {
        setBoundOrgId(state,val){
            state.boundOrgId = val
        }
    }
   
}

export default app;